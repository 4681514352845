@import '~ethos-design-system/src/components/Media/Media.scss';

.highlightBackground {
  display: flex;
  justify-content: center;
  width: 100%;
  &.buttercup {
    background-color: var(--BrandButtercup);
  }

  &.moss {
    background-color: var(--BrandMoss);
  }

  &.duckegg {
    background-color: var(--BrandDuckegg);
  }

  &.white,
  &.greenOrb {
    background-color: var(--BrandWhite);
  }

  &.lime {
    background-color: #fbffec;
  }

  &.mossLight {
    background-color: #edf6f2;
  }

  &.greenOrb {
    position: relative;
    max-width: 1440px;
    min-height: 400px;
    margin: auto;

    @include for-tablet-and-up {
      min-height: 670px;
    }
  }
}

.thirtyDayTrialBadge {
  background-color: #05594f;
  border-radius: 20px;
  padding: 10px 16px 8px;
  display: inline-block;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.14px;
}

.orb {
  position: absolute;
  z-index: -1;
  top: -100px;
  background: #056257;
  opacity: 0.1;
  filter: blur(60px);
  width: 570px;
  height: 570px;
  border-radius: 285px;

  &.hideMobile {
    @include for-phone-only {
      display: none;
    }
  }

  @include for-tablet-and-up {
    top: 0;
    width: 800px;
    height: 800px;
    border-radius: 400px;
  }

  &.orbLeft {
    left: 0;
    @include for-phone-only {
      left: -330px;
      top: 0;
    }
  }
  &.orbRight {
    right: 0;
    @include for-phone-only {
      right: -330px;
      top: 0;
    }
  }
}

.highlightWrapper {
  width: 100%;
  max-width: var(--ContainerMaxWidth);

  // Sets the position of the media to the right or left
  &.right {
    flex-direction: row;

    @include for-laptop-only {
      > div:first-child {
        margin-right: var(--Space-64);
      }
      > div:last-child {
        margin-left: var(--Space-64);
      }
    }

    @include for-desktop-only {
      > div:first-child {
        margin-right: 160px;
      }
    }
  }

  &.left {
    flex-direction: row-reverse;

    @include for-laptop-only {
      > div:first-child {
        margin-left: var(--Space-64);
      }
      > div:last-child {
        margin-right: var(--Space-64);
      }
    }

    @include for-desktop-only {
      > div:first-child {
        margin-left: 160px;
      }
    }
  }

  @include for-phone-only {
    padding-left: var(--Space-24);
    padding-right: var(--Space-24);
  }

  @include for-tablet-and-up {
    figure img,
    .videoEmbedWrapper {
      height: auto;
      max-width: 100%;
    }
  }

  @include for-tablet-only {
    margin: 0 var(--Space-48);
    max-width: 560px;
  }

  @include for-laptop-only {
    padding-left: var(--Space-64);
    padding-right: var(--Space-64);
  }

  @include for-laptop-and-up {
    display: flex;
    justify-content: space-between;
    .highlightText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    .highlightMedia {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include for-desktop-only {
    padding-left: var(--Space-80);
    padding-right: var(--Space-80);
  }
}

.nslpHighlightWrapper {
  @extend .highlightWrapper;

  justify-content: center;

  @include for-tablet-only {
    max-width: var(--ContainerMaxWidth);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .highlightMedia {
    width: 100%;
    .videoEmbedWrapper,
    img {
      max-height: 400px;
      max-width: 400px;
      object-fit: contain;
      border-radius: 0px;
      @include for-phone-only {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @include for-tablet-and-up {
      max-width: 400px;
    }
  }
  .highlightText {
    @include for-phone-only {
      text-align: center;
    }
    @include for-tablet-and-up {
      max-width: 340px;

      .highlightTextInner {
        max-width: 340px;
      }
    }
    @include for-desktop-only {
      padding-top: var(--Space-80);
    }
  }
  .spacerMediaBottom {
    @include for-phone-only {
      height: 0px;
    }
  }
  a {
  }
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    // !important used to override button properties from EDS
    padding: 16px 64px !important;
    width: auto !important;
    font-size: 17.5px !important;

    background: #056257;
    border-radius: 8px;
    color: white;
    cursor: pointer;
  }
  .ctaPhoneOnly {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-tablet-and-up {
      display: none;
    }
  }
}

.highlightMarkdown {
  > p,
  > ul,
  > ol,
  > ul > li,
  > ol > li {
    &:first-child {
      margin-top: 0;
      margin-bottom: 8px;
    }

    margin: var(--Space-8) 0;

    &:last-child {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
  br {
    content: '';
    margin-top: 20px;
    display: block;
  }
}

.highlightHeading {
  padding-bottom: var(--Space-16);

  @include for-tablet-only {
    padding-bottom: var(--Space-24);
  }

  @include for-laptop-and-up {
    padding-bottom: var(--Space-32);
  }
}

.highlightForegroundHeading {
  padding-bottom: var(--Space-8);
}

.highlightForegroundImage {
  display: none;

  @include for-laptop-and-up {
    display: initial;
  }
}

// Text columns
.highlightText {
  @include for-phone-only {
    margin-top: var(--Space-48);
  }

  @include for-tablet-only {
    margin-top: var(--Space-64);
  }

  @include for-laptop-only {
    padding-top: var(--Space-72);
  }

  @include for-desktop-only {
    padding-top: var(--Space-80);
    max-width: 560px;

    .highlightTextInner {
      max-width: 480px;
    }
  }
}

.highlightForegroundText {
  @extend .highlightText;

  @include for-laptop-and-up {
    padding-top: 0;
  }
}

// Media Columns
.highlightMedia {
  width: 100%;
  .videoEmbedWrapper,
  img {
    max-height: 700px;
    max-width: 700px;
    object-fit: contain;
    border-radius: 0px;
  }

  @include for-desktop-only {
    max-width: 560px;
  }
}

// Media query specific spacing and visibility
.mediaTitleHiddenPhoneAndTablet {
  display: none;

  @include for-laptop-and-up {
    display: block;
  }
}

.spacerMediaTop {
  @include for-phone-only {
    height: var(--Space-40);
  }

  @include for-tablet-only {
    height: var(--Space-48);
  }

  @include for-laptop-only {
    height: var(--Space-72);
  }

  @include for-desktop-only {
    height: var(--Space-80);
  }
}

.spacerMediaBottom {
  @include for-phone-only {
    height: var(--Space-48);
  }

  @include for-tablet-only {
    height: var(--Space-64);
  }

  @include for-laptop-only {
    height: var(--Space-72);
  }

  @include for-desktop-only {
    height: var(--Space-80);
  }
}

.spacerMarkdownBottom {
  @include for-laptop-and-up {
    height: var(--Space-80);
  }
}

.spacerCaption {
  @include for-phone-only {
    height: var(--Space-8);
  }

  @include for-tablet-and-up {
    height: var(--Space-16);
  }
}

.ctaTabletAndUp {
  display: block;

  @include for-phone-only {
    display: none;
  }
}

.ctaPhoneOnly {
  display: block;

  @include for-tablet-and-up {
    display: none;
  }
}

.ctaContainer {
  width: 307px;
}

.subHeading {
  color: #056257;
  font-family: Theinhardt;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  @include for-phone-only {
    font-size: 16px;
    line-height: normal;
    letter-spacing: 1.28px;
  }
}

.greenOrb .nslpHighlightWrapper {
  margin-top: var(--Space-64);
  @include for-tablet-only {
    gap: 30px;
  }
  @include for-laptop-only {
    gap: 60px;
  }
  @include for-desktop-only {
    gap: 120px;
  }

  @include for-phone-only {
    margin-top: var(--Space-32);
    padding-left: var(--Space-16);
    padding-right: var(--Space-16);
  }

  &.right {
    @include for-laptop-only {
      > div:first-child {
        margin-right: 0;
      }
      > div:last-child {
        margin-left: 0;
      }
    }

    @include for-desktop-only {
      > div:first-child {
        margin-right: 0;
      }
    }
  }

  &.left {
    @include for-laptop-only {
      > div:first-child {
        margin-left: 0;
      }
      > div:last-child {
        margin-right: 0;
      }
    }

    @include for-desktop-only {
      > div:first-child {
        margin-left: 0;
      }
    }
  }

  .highlightMedia,
  .highlightMedia img {
    max-width: 550px;
    max-height: 502px;

    @include for-phone-only {
      max-width: 333px;
      max-height: 304px;
    }
  }

  .highlightMedia {
    @include for-phone-only {
      position: relative;
      width: 100%;
      max-width: none;
      height: 304px;
      img {
        position: absolute;
        top: 0;
      }
      &.right img {
        right: -48px;
      }
      &.left img {
        left: -48px;
      }
    }
  }

  .highlightText,
  .highlightTextInner {
    justify-content: start;
    padding-top: 0;
    max-width: 514px;
    @include for-phone-only {
      margin-top: 40px;
    }

    * {
      text-align: left;
    }
  }

  .highlightHeading * {
    color: #000;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px; /* 133.333% */
    letter-spacing: -0.96px;
    margin-top: var(--Space-24);
    @include for-phone-only {
      font-size: 32px;
      line-height: 42px; /* 131.25% */
      letter-spacing: -0.64px;
      margin-bottom: var(--Space-12);
    }
  }

  .highlightMarkdown {
    strong {
      font-weight: 700;
    }
    * {
      color: #000;
      font-size: 28px;
      line-height: 40px; /* 142.857% */
      letter-spacing: -0.28px;
      @include for-phone-only {
        font-size: 20px;
        line-height: 28px; /* 140% */
      }
    }
  }
}

.body {
  line-height: 500%;
}
